import React,{useState,useEffect} from 'react'
import {Link,Navigate,useNavigate} from 'react-router-dom'
import axios from 'axios'
import './index.scss'
const apiUrl ='https://metaserverapp.com'


export default function Admin() {

  const initial = {
    phone:'',
    password:'',
    name:''
  }

  const menuToken = window.localStorage.getItem('menuToken')

  const [isCreate,setIsCreate]=useState(false)
  const [user,setUser] = useState(initial)
  const [page,setPage] = useState(1)
  const [total,setTotal]=useState(0)
  const [totalPage,setTotalPage] = useState(0)
  const [users,setUsers]=useState([])
  const [isDelete,setIsDelete]=useState(false)

  const handleDelete=()=>{
    setIsDelete(true)
  }
  const handleCancelDelete=()=>{
    setIsDelete(false)
  }
  const handleSubmitDelete=async(i)=>{
    console.log(i)
    const result = await axios.post(`${apiUrl}/auth/menu/delete`,{id:users[i]._id})
    if(result.data.success){
      alert('删除成功')
      window.location.reload()
    }
    else{
      alert('删除失败')
      window.location.reload()

    }
  }

  useEffect(()=>{
    const fetchUsers = async()=>{
      const result = await axios.get(`${apiUrl}/auth/menu/all`,{params:{page:1}})
      console.log(result);
      if(result.data.success){
     
          setUsers(result.data.data[0].result)

        
      
        const temp = result.data.data[0].total[0].total
        setTotal(result.data.data[0].total[0].total)
        const temp2 = Math.ceil(temp/15)
        setTotalPage(temp2)
     

      }
      else{
        alert('Server Error! No data response received!')
      }
  }
  fetchUsers()

  },[])

  useEffect(()=>{
    const fetchUsers = async()=>{
      const result = await axios.get(`${apiUrl}/auth/menu/all`,{params:{page:page}})
      console.log(result);
      if(result.data.success){
     
          setUsers(result.data.data[0].result)

        
      
        const temp = result.data.data[0].total[0].total
        setTotal(result.data.data[0].total[0].total)
        const temp2 = Math.ceil(temp/15)
        setTotalPage(temp2)
     

      }
      else{
        alert('Server Error! No data response received!')
      }
  }
  fetchUsers()

  },[page])

  const handleCreate=()=>{
    setIsCreate(true)
  }
  const handleCloseCreate=()=>{
    setIsCreate(false)

  }

  const handlePhone=(e)=>{
    let temp = {...user}
    temp.phone = e.target.value
    setUser(temp)

  }

  const handlePassword = (e)=>{
    let temp = {...user}
    temp.password = e.target.value
    setUser(temp)
  }
  const handleName = (e)=>{
    let temp = {...user}
    temp.name = e.target.value
    setUser(temp)
  }

  console.log(users)

  const handleSubmit=async()=>{
      if(!user.name||!user.phone||!user.password){
        alert('Missing Phone or Password or Name')
      }
      const result = await axios.post(`${apiUrl}/auth/menu`,{phone:user.phone,password:user.password,name:user.name})
      if(result.data.success){
        alert('创建成功')
        window.location.reload()
      }
      else{
        alert('创建出现了问题！')
      }
  }

  const handleNextPage = ()=>{
    setPage(page+1)
    const wrap =document.getElementsByClassName('admin-wrap-users')[0]
    wrap.scrollTop = 0
  }
  const handleLastPage = ()=>{
    setPage(page-1)
    const wrap =document.getElementsByClassName('admin-wrap-users')[0]
    wrap.scrollTop = 0

  }

  return (

    menuToken ? 
    <div className='admin-container'>

      <h2>餐馆menu大师管理后台</h2>

      <div className='admin-wrap'>

      <div className='admin-wrap-top'>
        <button onClick={()=>handleCreate()}>
          创建用户
        </button>
        <Link to='/admin/dashboard/img' >
        <button>图片库管理</button>
        </Link>
      </div>

      <div className='admin-wrap-users'>
      <table>
            <thead>
                <tr>
                    <th>
                        用户名
                    </th>
                    <th>
                        手机号
                    </th>
                    <th>
                        密码
                    </th>

                    <th>
                        日期
                    </th>
  
                    <th>
                        删除
                    </th>
                    <th>
                        更改
                    </th>
                </tr>
            </thead>
            <tbody>
                {
                    users.length>0&&users.map((v,i)=>(
                        <tr key={i}>
                            <td>{v.name}</td>
                            <td>{v.phone}</td>
                            <td>{v.password}</td>
                            <td>{v.date.substring(0,10)}</td>

                          <td>{isDelete?<><button onClick={()=>handleSubmitDelete(i)}>确定</button> <button onClick={()=>handleCancelDelete()}>取消</button></>:<button onClick={()=>handleDelete()}>删除</button>}</td>
                          <td><button>更改</button></td>
                         
                            
                            
                            
                        </tr>
                    )

                    )
                }
            </tbody>


        </table>

      </div>
      <div className='pagenation-wrap'>

<div> {page!==1&&<p onClick={()=>handleLastPage()}>上一页</p>}</div>

<div> <p>第{page}页</p> </div>

<div> {page !== totalPage&&<p onClick={()=>handleNextPage()}>下一页</p>}</div>

<div><p>共{totalPage}页</p></div>
<div>
<p>共{total}个结果</p>
</div>
</div>
      </div>

      <div style={{position:'absolute',top: '10vh',transition:'1.5s',scale:isCreate?'1':'0'}} className='admin-create'>
          <img onClick={()=>handleCloseCreate()} id='close-icon' src="https://res.cloudinary.com/dvr397xda/image/upload/v1666643028/remove_dywpuk.png" alt="" />

          <div className='admin-create-item'>
            <label >手机号</label>
            <input onChange={(e)=>handlePhone(e)} value={user.phone} type="text" />
          </div>

          <div className='admin-create-item'>
            <label >密码(最少6位数)</label>
            <input onChange={(e)=>handlePassword(e)} value={user.password} type="text" />
          </div>

          <div className='admin-create-item'>
            <label >用户名</label>
            <input onChange={(e)=>handleName(e)} value={user.name} type="text" />
          </div>

          <button onClick={()=>handleSubmit()}>创建</button>

        </div>
      


    </div>
    :<Navigate to='/admin/login' />
    
  )
}
